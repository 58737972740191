import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export function alertToast(msg, type, customConfig, dismissAll) {
  let config = {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    toastId: undefined,
  }

  if (dismissAll) {
    dismissAllToast()
  }

  switch (type) {
    case 'success':
      return toast.success(msg, { ...config, ...customConfig })
    case 'error':
      return toast.error(msg, { ...config, ...customConfig })
    default:
      return toast(msg, { ...config, ...customConfig })
  }
}

export function dismissAllToast() {
  toast.dismiss()
}

export class ToastAlert extends Component {
  render() {
    return <ToastContainer />
  }
}
