// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { TIME_IDLE_LOGOUT, URL_LOGOUT } from 'src/constants/config'
import { StateContext } from 'src/state'
import firebase from 'src/services/firebaseService'
import * as UserContext from 'src/context/UserContext'

type Props = {
  contextUser: any,
  location: any,
  history: any,
  children: React.Node | Array<React.Node>,
}

class IdleSecurityContainer extends Component<Props> {
  static contextType = StateContext

  _isMounted = false
  _idleTime = 0
  _idleInterval = null

  componentDidMount() {
    this._isMounted = true
    this.initTimer()
    // this.fibaseAuthListen()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props

    if (nextProps.location.pathname !== location.pathname) {
      if (!this._idleInterval) {
        this.initTimer()
      }
    }
  }

  componentWillUnmount() {
    this.disableTimer()
  }

  onKeyPress = () => {
    this._idleTime = 0
  }

  onMouseMove = () => {
    this._idleTime = 0
  }

  initTimer = () => {
    const [{ user }] = this.context
    if (user.logged) {
      this._idleInterval = setInterval(this.timerIncrement, TIME_IDLE_LOGOUT * 1000)
      window.addEventListener('mousemove', this.onMouseMove, false)
      window.addEventListener('keypress', this.onKeyPress, false)
    }
  }

  fibaseAuthListen = () => {
    if (this._isMounted) {
      const [, dispatch] = this.context
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken(true)

          await dispatch({
            type: UserContext.TYPES.SET_FIREBASE_TOKEN,
            payload: token,
          })
        }
      })
    }
  }

  disableTimer = () => {
    window.removeEventListener('mousemove', this.onMouseMove, false)
    window.removeEventListener('keypress', this.onKeyPress, false)
    clearInterval(this._idleInterval)
  }

  timerIncrement = () => {
    const [{ user }] = this.context

    this._idleTime += 1

    if (this._idleTime > TIME_IDLE_LOGOUT && user.logged) {
      console.log('deslogou')
      this.disableTimer()
      window.location.href = URL_LOGOUT
    }
  }

  render() {
    const { children } = this.props
    return <React.Fragment>{children}</React.Fragment>
  }
}

export default withRouter(IdleSecurityContainer)
