import * as storage from '../helpers/storage'
import { ENUM_PERFIL_USUARIO as PERFIL } from 'src/constants/config'

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser()
  if (!user || !user.logged) {
    return false
  }

  return true
}

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = storage.getItem('user')
  return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null
}

const routeUserAuthenticated = (levelRoute) => {
  const user = getLoggedInUser()
  const IDPerfilAcesso = user && user.data && user.data.IDPerfilAcesso

  if (IDPerfilAcesso) {
    const filterPerfil = (value) => PERFIL.filter((item) => item.Codigo === value && item.Nivel)
    const [nivelPerfilAcesso] = filterPerfil(IDPerfilAcesso)
    const [nivelAcessoRoute] = filterPerfil(levelRoute)
    const semPermissaoAcesso = nivelPerfilAcesso.Nivel < nivelAcessoRoute.Nivel

    return semPermissaoAcesso
  }

  return false
}

export { isUserAuthenticated, getLoggedInUser, routeUserAuthenticated }
