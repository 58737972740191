import * as CONSTANTS from 'src/constants/config'
import * as environent from 'src/helpers/environment'
import * as jsonParse from './jsonParse'

const storage = environent.isDevelopment() ? localStorage : sessionStorage
const version = CONSTANTS.APP_VERSION

const compositeName = (name) => {
  return `${CONSTANTS.STORAGE_NAME}-${version}-${name}`.toUpperCase()
}

export const setItem = (name, value) => {
  storage.setItem(compositeName(name), JSON.stringify(value))
}

export const getItem = (name) => {
  const localData = storage.getItem(compositeName(name))

  if (localData && jsonParse.isValid(localData)) {
    return JSON.parse(localData)
  }

  if (localData === 'undefined' || localData === 'null') {
    return null
  }

  return localData
}

export const removeItem = (name) => {
  return storage.removeItem(compositeName(name))
}

export const clear = () => {
  storage.clear()
}
