const initialStateDefault = {
  data: null,
}

export const TYPES = {
  SET_QUERY: 'setQuery',
  CLEAR_QUERY: 'clearQuery',
}

export const initialState = initialStateDefault

export const reducer = (state, action) => {
  let newState = state
  switch (action.type) {
    case TYPES.SET_QUERY:
      newState = {
        ...state,
        data: parseSetQuery(state, action.payload),
      }
      break

    case TYPES.CLEAR_QUERY:
      newState = {
        ...initialState,
      }
      break

    default:
      break
  }

  return newState
}

function parseSetQuery(state, payload) {
  if (payload) {
    const { data, ...newPayload } = payload

    return {
      ...(state && state.data ? state.data : null),
      ...newPayload,
    }
  }

  return null
}
