import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error) {
    // TODO: Implementer fetch para API de logs passando `error`
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary-fallback">
          <h1>Opps, algo deu errado!</h1>
          <h2>Nossa equipe técnica já foi avisada sobre esse erro.</h2>
          <a href="/" className="btn btn-primary">
            Voltar para o início
          </a>
          <img src="/images/icons/notify_error.svg" alt="Algo deu errado" />
        </div>
      )
    }

    return this.props.children
  }
}
