import { subMonths } from 'date-fns'

import * as storage from 'src/helpers/storage'
import * as CONSTANTS from 'src/constants/config'
import { STATUS_AUTOMACOES } from 'src/constants/config'

const storageName = CONSTANTS.STORAGE_OPERACOES_DATA
const localStateStorage = storage.getItem(storageName)
const date = new Date()
const initialStateDefault = {
  data: [],
  infoPaginacao: {
    Paginacao: {
      PaginaAtual: 0,
      RegistrosPorPagina: 0,
      TotalDeRegistros: 0,
    },
  },
  loading: false,
  error: '',
  comFiltro: false,
  filtro: {
    Status: STATUS_AUTOMACOES.ERRO,
    IdEmpresa: null,
    TipoAutomacao: null,
    CompetenciaInicio: subMonths(new Date(date.getFullYear(), date.getMonth(), 1), 1),
    CompetenciaFim: subMonths(new Date(date.getFullYear(), date.getMonth() + 1, 0), 1),
  },
  count: {
    QtdAutomacoesErro: 0,
    QtdAutomacoesAProcessar: 0,
    QtdAutomacoesEmExecucao: 0,
    QtdAutomacoesCanceladas: 0,
    QtdAutomacoesSucesso: 0,
  },
  selectedItems: {
    selectRow: {
      selected: [],
    },
    allItemsSelected: false,
  },
}

export const TYPES = {
  SET_DATA: 'operacoes.setData',
  SET_FILTRO_DEFAULT: 'operacoes.setFiltroDefault',
  SET_CLEAR_PESQUISA: 'operacoes.setClearPesquisa',
  SET_PESQUISA: 'operacoes.setPesquisa',
  SET_LOADING: 'operacoes.setLoading',
  SET_ERROR: 'operacoes.setError',
  SET_STATUS: 'operacoes.setStatus',
  SET_EMPRESA: 'operacoes.setEmpresa',
  SET_COMPETENCIA: 'operacoes.setCompetencia',
  SET_TIPO: 'operacoes.setTipo',
  SET_COUNT: 'operacoes.setCount',
  SET_COUNT_DEFAULT: 'operacoes.setCountDefault',
  SET_SELECTED_ITEMS: 'operacoes.setSelectedItems',
  SET_SELECTED_ITEMS_DEFAULT: 'operacoes.setSelectedItemsDefault',
}

export const initialState = localStateStorage || initialStateDefault

export const reducer = (state, action) => {
  let newState = state
  switch (action.type) {
    case TYPES.SET_CLEAR_PESQUISA:
      newState = {
        ...state,
        filtro: {
          Status: state.filtro.Status,
          IdEmpresa: state.filtro.IdEmpresa,
          TipoAutomacao: state.filtro.TipoAutomacao,
          CompetenciaInicio: state.filtro.CompetenciaInicio,
          CompetenciaFim: state.filtro.CompetenciaFim,
        },
        selectedItems: { ...initialStateDefault.selectedItems },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_DATA:
      newState = {
        ...state,
        loading: false,
        error: '',
        data: [...action.payload.Lista],
        infoPaginacao: {
          Paginacao: {
            ...(action.payload && action.payload.Paginacao ? action.payload.Paginacao : {}),
          },
        },
        selectedItems: { ...initialStateDefault.selectedItems },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_FILTRO_DEFAULT:
      newState = {
        ...state,
        filtro: {
          ...initialStateDefault.filtro,
          IdEmpresa: null,
          TipoAutomacao: null,
          CompetenciaInicio: subMonths(new Date(), 1),
          CompetenciaFim: subMonths(new Date(), 1),
        },
        comFiltro: false,
        selectedItems: { ...initialStateDefault.selectedItems },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_LOADING:
      newState = {
        ...state,
        loading: true,
        data: [],
        infoPaginacao: { ...initialStateDefault.infoPaginacao },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_ERROR:
      newState = {
        ...state,
        loading: false,
        error: action.payload,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_COMPETENCIA:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          CompetenciaInicio: action.payload.CompetenciaInicio,
          CompetenciaFim: action.payload.CompetenciaFim,
        },
        comFiltro: true,
        selectedItems: { ...initialStateDefault.selectedItems },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_STATUS:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          Status: action.payload,
        },
        selectedItems: { ...initialStateDefault.selectedItems },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_EMPRESA:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          IdEmpresa: action.payload,
        },
        comFiltro: true,
        selectedItems: { ...initialStateDefault.selectedItems },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_TIPO:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          TipoAutomacao: action.payload,
        },
        comFiltro: true,
        selectedItems: { ...initialStateDefault.selectedItems },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_PESQUISA:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          [`Pesquisa${action.payload.name}`]: action.payload.value,
        },
        selectedItems: { ...initialStateDefault.selectedItems },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_COUNT:
      newState = {
        ...state,
        count: {
          ...state.count,
          ...action.payload,
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_COUNT_DEFAULT:
      newState = {
        ...state,
        count: {
          ...initialStateDefault.count,
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_SELECTED_ITEMS:
      newState = {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          ...action.payload,
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_SELECTED_ITEMS_DEFAULT:
      newState = {
        ...state,
        selectedItems: {
          ...initialStateDefault.selectedItems,
        },
      }
      storage.setItem(storageName, newState)
      break

    default:
      break
  }

  return newState
}
