import * as storage from 'src/helpers/storage'
import * as CONSTANTS from 'src/constants/config'
import { subMonths } from 'date-fns'

const storageName = CONSTANTS.STORAGE_PAINEL_FECHAMENTO
const localStateStorage = storage.getItem(storageName)
const date = new Date()
const initialStateDefault = {
  data: [],
  infoPaginacao: {
    Paginacao: {
      PaginaAtual: 0,
      RegistrosPorPagina: 0,
      TotalDeRegistros: 0,
    },
  },
  loading: false,
  error: '',
  comFiltro: false,
  filtro: {
    Competencia: subMonths(new Date(date.getFullYear(), date.getMonth(), 1), 1),
    idEmpresa: null,
    IdPerfisEscritoriosContabeis: null,
    IDCategoria: null,
    idAnexo: null,
  },
}

export const TYPES = {
  SET_DATA: 'painelFechamento.setData',
  SET_FILTRO_DEFAULT: 'painelFechamento.setFiltroDefault',
  SET_CLEAR_PESQUISA: 'painelFechamento.setClearPesquisa',
  SET_PESQUISA: 'painelFechamento.setPesquisa',
  SET_LOADING: 'painelFechamento.setLoading',
  SET_ERROR: 'painelFechamento.setError',
  SET_TAB: 'painelFechamento.setTab',
  SET_COMPETENCIA: 'painelFechamento.setCompetencia',
  SET_EMPRESA: 'painelFechamento.setEmpresa',
  SET_CONTADOR_RESP: 'painelFechamento.setContadorResp',
  SET_CATEGORIA: 'painelFechamento.setCategoria',
  SET_ANEXO: 'painelFechamento.setAnexo',
}

export const initialState = localStateStorage || initialStateDefault

export const reducer = (state, action) => {
  let newState = state
  switch (action.type) {
    case TYPES.SET_CLEAR_PESQUISA:
      newState = {
        ...state,
        filtro: {
          Competencia: state.filtro.Competencia,
          idEmpresa: state.filtro.idEmpresa,
          IdPerfisEscritoriosContabeis: state.filtro.IdPerfisEscritoriosContabeis,
          IDCategoria: state.filtro.IDCategoria,
          idAnexo: state.filtro.idAnexo,
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_DATA:
      newState = {
        ...state,
        loading: false,
        error: '',
        data: [...action.payload.Lista],
        infoPaginacao: {
          Paginacao: {
            ...(action.payload && action.payload.Paginacao ? action.payload.Paginacao : {}),
          },
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_FILTRO_DEFAULT:
      newState = {
        ...state,
        filtro: {
          ...initialStateDefault.filtro,
          Competencia: subMonths(new Date(), 1),
          idEmpresa: null,
          IdPerfisEscritoriosContabeis: null,
          IDCategoria: null,
          idAnexo: null,
        },
        comFiltro: false,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_LOADING:
      newState = {
        ...state,
        loading: true,
        data: [],
        infoPaginacao: { ...initialStateDefault.infoPaginacao },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_ERROR:
      newState = {
        ...state,
        loading: false,
        error: action.payload,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_TAB:
      newState = {
        ...state,
        tab: action.payload,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_COMPETENCIA:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          Competencia: action.payload,
        },
        comFiltro: true,
        selectedItems: { ...initialStateDefault.selectedItems },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_EMPRESA:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          idEmpresa: action.payload,
        },
        comFiltro: true,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_CONTADOR_RESP:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          IdPerfisEscritoriosContabeis: action.payload,
        },
        comFiltro: true,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_CATEGORIA:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          IDCategoria: action.payload,
        },
        comFiltro: true,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_ANEXO:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          idAnexo: action.payload,
        },
        comFiltro: true,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_PESQUISA:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          [action.payload.name]: action.payload.value,
        },
      }
      storage.setItem(storageName, newState)
      break

    default:
      break
  }

  return newState
}
