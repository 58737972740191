import * as storage from 'src/helpers/storage'
import * as CONSTANTS from 'src/constants/config'
import { addMonths } from 'date-fns'

const storageName = CONSTANTS.STORAGE_PAINEL_COMUNICACAO
const localStateStorage = storage.getItem(storageName)
const date = new Date()
const initialStateDefault = {
  data: [],
  infoPaginacao: {
    Paginacao: {
      PaginaAtual: 0,
      RegistrosPorPagina: 0,
      TotalDeRegistros: 0,
    },
  },
  loading: false,
  error: '',
  comFiltro: false,
  filtro: {
    MesCompetencia: addMonths(date, 1).getMonth(),
    AnoCompetencia: date.getFullYear(),
    idEmpresa: null,
    idContabilidade: null,
  },
}

export const TYPES = {
  SET_DATA: 'painelComunicacao.setData',
  SET_FILTRO_DEFAULT: 'painelComunicacao.setFiltroDefault',
  SET_CLEAR_PESQUISA: 'painelComunicacao.setClearPesquisa',
  SET_PESQUISA: 'painelComunicacao.setPesquisa',
  SET_LOADING: 'painelComunicacao.setLoading',
  SET_ERROR: 'painelComunicacao.setError',
  SET_TAB: 'painelComunicacao.setTab',
  SET_COMPETENCIA: 'painelComunicacao.setCompetencia',
  SET_EMPRESA: 'painelComunicacao.setEmpresa',
  SET_CONTADOR_RESP: 'painelComunicacao.setContadorResp',
}

export const initialState = localStateStorage || initialStateDefault

export const reducer = (state, action) => {
  let newState = state
  switch (action.type) {
    case TYPES.SET_CLEAR_PESQUISA:
      newState = {
        ...state,
        filtro: {
          MesCompetencia: state.filtro.MesCompetencia,
          AnoCompetencia: state.filtro.AnoCompetencia,
          idEmpresa: state.filtro.idEmpresa,
          idContabilidade: state.filtro.idContabilidade,
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_DATA:
      newState = {
        ...state,
        loading: false,
        error: '',
        data: [...action.payload.Dados],
        infoPaginacao: {
          Paginacao: {
            PaginaAtual: action.payload.Paginacao?.PaginaAtual,
            RegistrosPorPagina: action.payload.Paginacao?.RegistrosPagina,
            TotalDeRegistros: action.payload.Paginacao?.RegistrosTotal,
          },
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_FILTRO_DEFAULT:
      newState = {
        ...state,
        filtro: {
          ...initialStateDefault.filtro,
          MesCompetencia: addMonths(date, 1).getMonth(),
          AnoCompetencia: date.getFullYear(),
          idEmpresa: null,
          idContabilidade: null, 
        },
        comFiltro: false,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_LOADING:
      newState = {
        ...state,
        loading: true,
        data: [],
        infoPaginacao: { ...initialStateDefault.infoPaginacao },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_ERROR:
      newState = {
        ...state,
        loading: false,
        error: action.payload,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_TAB:
      newState = {
        ...state,
        tab: action.payload,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_COMPETENCIA:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          MesCompetencia: action.payload.MesCompetencia,
          AnoCompetencia: action.payload.AnoCompetencia,
        },
        comFiltro: true,
        selectedItems: { ...initialStateDefault.selectedItems },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_EMPRESA:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          idEmpresa: action.payload,
        },
        comFiltro: true,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_CONTADOR_RESP:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          idContabilidade: action.payload,
          idEmpresa: null,
        },
        comFiltro: true,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_PESQUISA:
      newState = {
        ...state,
        filtro: {
          ...state.filtro,
          [action.payload.name]: action.payload.value,
        },
      }
      storage.setItem(storageName, newState)
      break

    default:
      break
  }

  return newState
}
