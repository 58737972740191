import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ROLE } from 'src/constants/config'
import { isUserAuthenticated, routeUserAuthenticated } from '../helpers/authUtils'

// auth
const Login = React.lazy(() => import('../pages/auth/Login'))
const Token = React.lazy(() => import('../pages/auth/Token'))
const Logout = React.lazy(() => import('../pages/auth/Logout'))
const Register = React.lazy(() => import('../pages/auth/Register'))
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'))
const NewPassword = React.lazy(() => import('../pages/auth/NewPassword'))
const Confirm = React.lazy(() => import('../pages/auth/Confirm'))

// Custom
const Painel = React.lazy(() => import('../pages/Painel'))
const Receitas = {
  Index: React.lazy(() => import('src/pages/receitas')),
  AguardandoEmissao: React.lazy(() => import('src/pages/receitas/AguardandoEmissao')),
  Clientes: React.lazy(() => import('src/pages/receitas/Clientes')),
  Lancamentos: React.lazy(() => import('src/pages/receitas/Lancamentos')),
  NotasFiscais: React.lazy(() => import('src/pages/receitas/NotasFiscais')),
}
const Despesas = {
  Index: React.lazy(() => import('src/pages/despesas')),
  Folha: React.lazy(() => import('src/pages/despesas/Folha')),
  Fornecedores: React.lazy(() => import('src/pages/despesas/Fornecedores')),
  Impostos: React.lazy(() => import('src/pages/despesas/Impostos')),
  Lancamentos: React.lazy(() => import('src/pages/despesas/Lancamentos')),
}
const Contabilidade = {
  Index: React.lazy(() => import('src/pages/contabilidade')),
}
const Contador = {
  EscritoriosContabeis: React.lazy(() => import('src/pages/contador/EscritoriosContabeis')),
  MeuEscritorio: React.lazy(() => import('src/pages/contador/MeuEscritorio')),
}
const Empresa = {
  Index: React.lazy(() => import('src/pages/empresa')),
  Clientes: React.lazy(() => import('src/pages/empresa/Clientes')),
  NovosClientes: React.lazy(() => import('src/pages/empresa/NovosClientes')),
}
const Usuarios = React.lazy(() => import('../pages/Usuarios'))
const RegrasFiscaisMunicipal = React.lazy(() => import('../pages/RegrasFiscaisMunicipal'))
const RegrasFiscaisFederal = React.lazy(() => import('../pages/RegrasFiscaisFederal'))
const MinhaConta = React.lazy(() => import('../pages/MinhaConta'))
const Declaracoes = React.lazy(() => import('../pages/Declaracoes'))
const GPS = React.lazy(() => import('../pages/static/GPS'))
const Arquivo = React.lazy(() => import('../pages/static/Arquivo'))
const Download = React.lazy(() => import('../pages/static/Download'))
const DARF = React.lazy(() => import('../pages/static/Darf'))
const Perfil = {
  Index: React.lazy(() => import('src/pages/perfil')),
  Automacao: React.lazy(() => import('src/pages/perfil/Automacao')),
  CertificadosDigitais: React.lazy(() => import('src/pages/perfil/CertificadosDigitais')),
  CNAE: React.lazy(() => import('src/pages/perfil/CNAE')),
  DadosCadastrais: React.lazy(() => import('src/pages/perfil/DadosCadastrais')),
  Historico: React.lazy(() => import('src/pages/perfil/Historico')),
  Integracao: React.lazy(() => import('src/pages/perfil/Integracao')),
  ProLabore: React.lazy(() => import('src/pages/perfil/ProLabore')),
  Socios: React.lazy(() => import('src/pages/perfil/Socios')),
  ContasCorrentes: React.lazy(() => import('src/pages/perfil/ContasCorrentes')),
}
const Operacoes = {
  Index: React.lazy(() => import('src/pages/operacoes')),
  Pendentes: React.lazy(() => import('src/pages/operacoes/Pendentes')),
  AProcessar: React.lazy(() => import('src/pages/operacoes/AProcessar')),
  Processando: React.lazy(() => import('src/pages/operacoes/Processando')),
  Canceladas: React.lazy(() => import('src/pages/operacoes/Canceladas')),
  Sucesso: React.lazy(() => import('src/pages/operacoes/Sucesso')),
}
const PainelConfiguracoes = {
  Index: React.lazy(() => import('src/pages/paineis/configuracoes')),
  Acessos: React.lazy(() => import('src/pages/paineis/configuracoes/Acessos')),
  Automacao: React.lazy(() => import('src/pages/paineis/configuracoes/Automacao')),
  IntegracaoJettax: React.lazy(() => import('src/pages/paineis/configuracoes/IntegracaoJettax')),
  IntegracaoMigrate: React.lazy(() => import('src/pages/paineis/configuracoes/IntegracaoMigrate')),
  FatorR: React.lazy(() => import('src/pages/paineis/configuracoes/FatorR')),
}
const PainelFechamento = {
  Index: React.lazy(() => import('src/pages/paineis/fechamento')),
  Faturamento: React.lazy(() => import('src/pages/paineis/fechamento/Faturamento')),
  ProLabore: React.lazy(() => import('src/pages/paineis/fechamento/ProLabore')),
  DAS: React.lazy(() => import('src/pages/paineis/fechamento/DAS')),
}
const PainelSocios = {
  Index: React.lazy(() => import('src/pages/paineis/socios')),
  Cadastro: React.lazy(() => import('src/pages/paineis/socios/Cadastro')),
  ProLabore: React.lazy(() => import('src/pages/paineis/socios/ProLabore')),
}

const PainelComunicacao = {
  Index: React.lazy(() => import('src/pages/paineis/comunicacao')),
  NotasFiscais: React.lazy(() => import('src/pages/paineis/comunicacao/NotasFiscais')),
  Impostos: React.lazy(() => import('src/pages/paineis/comunicacao/Impostos')),
  ProLabore: React.lazy(() => import('src/pages/paineis/comunicacao/ProLabore')),
}

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/conta/entrar', state: { from: props.location } }} />
      }

      if (roles && routeUserAuthenticated(roles)) {
        return <Redirect to="/painel" />
      }

      // authorised so return component
      return <Component {...props} />
    }}
  />
)

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to="/painel" />,
  route: PrivateRoute,
}

// dashboards
const dashboardRoutes = {
  path: '/painel',
  name: 'Painel',
  icon: 'mdi mdi-speedometer',
  exact: true,
  component: Painel,
  route: PrivateRoute,
}

// receitasRoutes
const receitasRoutes = {
  Index: {
    path: '/receitas',
    name: 'Receitas',
    icon: 'mdi mdi-bank-transfer-in',
    exact: true,
    component: Receitas.Index,
    route: PrivateRoute,
  },
  AguardandoEmissao: {
    path: '/receitas/aguardando-emissao',
    name: 'Aguardando Emissão',
    icon: 'mdi mdi-bank-transfer-in',
    exact: true,
    component: Receitas.AguardandoEmissao,
    route: PrivateRoute,
  },
  Clientes: {
    path: '/receitas/clientes',
    name: 'Clientes',
    icon: 'mdi mdi-bank-transfer-in',
    exact: true,
    component: Receitas.Clientes,
    route: PrivateRoute,
  },
  Lancamentos: {
    path: '/receitas/lancamentos',
    name: 'Lançamentos',
    icon: 'mdi mdi-bank-transfer-in',
    exact: true,
    component: Receitas.Lancamentos,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  NotasFiscais: {
    path: '/receitas/notas-fiscais',
    name: 'Notas Fiscais',
    icon: 'mdi mdi-bank-transfer-in',
    exact: true,
    component: Receitas.NotasFiscais,
    route: PrivateRoute,
  },
}

// despesasRoutes
const despesasRoutes = {
  Index: {
    path: '/despesas',
    name: 'Despesas',
    icon: 'mdi mdi-bank-transfer-out',
    exact: true,
    component: Despesas.Index,
    route: PrivateRoute,
  },
  Folha: {
    path: '/despesas/folha',
    name: 'Folha',
    icon: 'mdi mdi-bank-transfer-out',
    exact: true,
    component: Despesas.Folha,
    route: PrivateRoute,
  },
  Fornecedores: {
    path: '/despesas/fornecedores',
    name: 'Fornecedores',
    icon: 'mdi mdi-bank-transfer-out',
    exact: true,
    component: Despesas.Fornecedores,
    route: PrivateRoute,
  },
  Impostos: {
    path: '/despesas/impostos',
    name: 'Impostos',
    icon: 'mdi mdi-bank-transfer-out',
    exact: true,
    component: Despesas.Impostos,
    route: PrivateRoute,
  },
  Lancamentos: {
    path: '/despesas/lancamentos',
    name: 'Lançamentos',
    icon: 'mdi mdi-bank-transfer-out',
    exact: true,
    component: Despesas.Lancamentos,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
}

// empresaRoutes
const empresaRoutes = {
  Index: {
    path: '/empresa',
    name: 'Empresa',
    icon: 'mdi mdi-briefcase-outline',
    exact: true,
    component: Empresa.Index,
    route: PrivateRoute,
    roles: ROLE.ADMINISTRADOR,
  },
  Clientes: {
    path: '/empresa/clientes',
    name: 'Clientes',
    icon: 'mdi mdi-briefcase-outline',
    exact: true,
    component: Empresa.Clientes,
    route: PrivateRoute,
    roles: ROLE.ADMINISTRADOR,
  },
  NovosClientes: {
    path: '/empresa/novos-clientes',
    name: 'Novos Clientes',
    icon: 'mdi mdi-briefcase-outline',
    exact: true,
    component: Empresa.NovosClientes,
    route: PrivateRoute,
    roles: ROLE.ADMINISTRADOR,
  },
}

const usersRoutes = {
  path: '/usuarios',
  name: 'Usuários',
  icon: 'mdi mdi-account-multiple',
  exact: true,
  component: Usuarios,
  route: PrivateRoute,
  roles: ROLE.ADMINISTRADOR,
}

const contabilidadeRoutes = {
  Index: {
    path: '/contabilidade',
    name: 'Contabilidade',
    icon: 'mdi mdi-calculator',
    exact: true,
    component: Contabilidade.Index,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
}

// Escritórios Contábeis
const contadorRoutes = {
  EscritoriosContabeis: {
    path: '/contador/escritorios-contabeis',
    name: 'Escritórios Contábeis',
    exact: true,
    component: Contador.EscritoriosContabeis,
    route: PrivateRoute,
    roles: ROLE.ROOT,
  },
  MeuEscritorio: {
    path: '/contador/meu-escritorio',
    name: 'Meu Escritório',
    exact: true,
    component: Contador.MeuEscritorio,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
}

// MinhaConta
const minhaContaRoutes = {
  path: '/minhaConta',
  name: 'MinhaConta',
  exact: true,
  component: MinhaConta,
  route: PrivateRoute,
}

// perfilRoutes
const perfilRoutes = {
  Index: {
    path: '/perfil',
    name: 'Perfil',
    exact: true,
    component: Perfil.Index,
    route: PrivateRoute,
    roles: ROLE.EMPRESA,
  },
  Automacao: {
    path: '/perfil/automacao',
    name: 'Automação',
    exact: true,
    component: Perfil.Automacao,
    route: PrivateRoute,
    roles: ROLE.ADMINISTRADOR,
  },
  CertificadosDigitais: {
    path: '/perfil/certificados-digitais',
    name: 'Certificados Digitais',
    exact: true,
    component: Perfil.CertificadosDigitais,
    route: PrivateRoute,
    roles: ROLE.EMPRESA,
  },
  CNAE: {
    path: '/perfil/cnae',
    name: 'CNAE',
    exact: true,
    component: Perfil.CNAE,
    route: PrivateRoute,
    roles: ROLE.EMPRESA,
  },
  DadosCadastrais: {
    path: '/perfil/dados-cadastrais',
    name: 'Dados Cadastrais',
    exact: true,
    component: Perfil.DadosCadastrais,
    route: PrivateRoute,
    roles: ROLE.EMPRESA,
  },
  Historico: {
    path: '/perfil/historico',
    name: 'Histórico',
    exact: true,
    component: Perfil.Historico,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  Integracao: {
    path: '/perfil/integracao',
    name: 'Integração',
    exact: true,
    component: Perfil.Integracao,
    route: PrivateRoute,
    roles: ROLE.ADMINISTRADOR,
  },
  ProLabore: {
    path: '/perfil/pro-labore',
    name: 'Pró-Labore',
    exact: true,
    component: Perfil.ProLabore,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  Socios: {
    path: '/perfil/socios',
    name: 'Sócios',
    exact: true,
    component: Perfil.Socios,
    route: PrivateRoute,
    roles: ROLE.EMPRESA,
  },
  ContasCorrentes: {
    path: '/perfil/contas-correntes',
    name: 'Contas Correntes',
    exact: true,
    component: Perfil.ContasCorrentes,
    route: PrivateRoute,
    roles: ROLE.ROOT,
  },
}

// operacoesRoutes
const operacoesRoutes = {
  Index: {
    path: '/processamento',
    name: 'Painel de Processamento',
    exact: true,
    component: Operacoes.Index,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  Pendentes: {
    path: '/processamento/pendentes',
    name: 'Pendentes',
    exact: true,
    component: Operacoes.Pendentes,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  AProcessar: {
    path: '/processamento/processar',
    name: 'A Processar',
    exact: true,
    component: Operacoes.AProcessar,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  Processando: {
    path: '/processamento/processando',
    name: 'Processando',
    exact: true,
    component: Operacoes.Processando,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  Canceladas: {
    path: '/processamento/canceladas',
    name: 'Canceladas',
    exact: true,
    component: Operacoes.Canceladas,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  Sucesso: {
    path: '/processamento/sucesso',
    name: 'Sucesso',
    exact: true,
    component: Operacoes.Sucesso,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
}

// Painel de Configurações
const painelConfiguracoesRoutes = {
  Index: {
    path: '/painel/configuracoes',
    name: 'Painel de Configurações',
    exact: true,
    component: PainelConfiguracoes.Index,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  Acessos: {
    path: '/painel/configuracoes/acessos',
    name: 'Acessos',
    exact: true,
    component: PainelConfiguracoes.Acessos,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  Automacao: {
    path: '/painel/configuracoes/automacao',
    name: 'Automação',
    exact: true,
    component: PainelConfiguracoes.Automacao,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  IntegracaoJettax: {
    path: '/painel/configuracoes/integracao-jettax',
    name: 'Integração Jettax',
    exact: true,
    component: PainelConfiguracoes.IntegracaoJettax,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  IntegracaoMigrate: {
    path: '/painel/configuracoes/integracao-migrate',
    name: 'Integração Migrate',
    exact: true,
    component: PainelConfiguracoes.IntegracaoMigrate,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  FatorR: {
    path: '/painel/configuracoes/fator-r',
    name: 'Fator R',
    exact: true,
    component: PainelConfiguracoes.FatorR,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
}

// Painel de Fechamento
const painelFechamentoRoutes = {
  Index: {
    path: '/painel/fechamento',
    name: 'Painel de Fechamento',
    exact: true,
    component: PainelFechamento.Index,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  Faturamento: {
    path: '/painel/fechamento/faturamento',
    name: 'Faturamento',
    exact: true,
    component: PainelFechamento.Faturamento,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  ProLabore: {
    path: '/painel/fechamento/pro-labore',
    name: 'Pró-Labore',
    exact: true,
    component: PainelFechamento.ProLabore,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  DAS: {
    path: '/painel/fechamento/das',
    name: 'DAS',
    exact: true,
    component: PainelFechamento.DAS,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
}

// Painel de Sócios
const painelSociosRoutes = {
  Index: {
    path: '/painel/socios',
    name: 'Painel de Sócios',
    exact: true,
    component: PainelSocios.Index,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  Cadastro: {
    path: '/painel/socios/cadastro',
    name: 'Cadastro',
    exact: true,
    component: PainelSocios.Cadastro,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
  ProLabore: {
    path: '/painel/socios/pro-labore',
    name: 'Pró-Labore',
    exact: true,
    component: PainelSocios.ProLabore,
    route: PrivateRoute,
    roles: ROLE.CONTADOR,
  },
}

// Painel Comunicação
const painelComunicacoRoutes = {
  Index: {
    path: '/painel/comunicacao',
    name: 'Painel Comunicação',
    exact: true,
    component: PainelComunicacao.Index,
    route: PrivateRoute,
    roles: ROLE.ADMINISTRADOR,
  },
  NotasFiscais: {
    path: '/painel/comunicacao/notas-fiscais',
    name: 'Painel Notas Fiscais',
    exact: true,
    component: PainelComunicacao.NotasFiscais,
    route: PrivateRoute,
    roles: ROLE.ADMINISTRADOR,
  },

  Impostos: {
    path: '/painel/comunicacao/impostos',
    name: 'Impostos',
    exact: true,
    component: PainelComunicacao.Impostos,
    route: PrivateRoute,
    roles: ROLE.ADMINISTRADOR,
  },
  ProLabore: {
    path: '/painel/comunicacao/pro-labore',
    name: 'Pró-Labore',
    exact: true,
    component: PainelComunicacao.ProLabore,
    route: PrivateRoute,
    roles: ROLE.ADMINISTRADOR,
  },
}

// Regras Fiscais
const regrasFiscaisMunicipalRoutes = {
  path: '/regras-fiscais-municipal',
  name: 'Regras Fiscais Municipal',
  exact: true,
  component: RegrasFiscaisMunicipal,
  route: PrivateRoute,
  roles: ROLE.CONTADOR,
}

const regrasFiscaisFederalRoutes = {
  path: '/regras-fiscais-federal',
  name: 'Regras Fiscais Federal',
  exact: true,
  component: RegrasFiscaisFederal,
  route: PrivateRoute,
  roles: ROLE.ADMINISTRADOR,
}

// declaracoesRoutes
const declaracoesRoutes = {
  path: '/declaracoes',
  name: 'Declarações',
  icon: 'mdi mdi-file-document-outline',
  disabled: true,
  exact: true,
  component: Declaracoes,
  route: PrivateRoute,
}

//GPSROutes
const gpsRoutes = {
  path: '/statics/gps/:id',
  name: 'GPS',
  icon: 'dripicons-document',
  exact: false,
  component: withRouter(GPS),
  route: PrivateRoute,
}

//ArquivoRoutes
const arquivoRoutes = {
  path: '/statics/arquivo/:url',
  name: 'Arquivo',
  exact: false,
  icon: 'dripicons-document',
  component: withRouter(Arquivo),
  route: PrivateRoute,
}

//DownloadRoutes
const downloadRoutes = {
  path: '/statics/download/:url/:name',
  name: 'Download',
  exact: false,
  icon: 'dripicons-document',
  component: withRouter(Download),
  route: PrivateRoute,
}

//GPSROutes
const darfRoutes = {
  path: '/statics/darf/:id',
  name: 'DARF',
  icon: 'dripicons-document',
  exact: false,
  component: withRouter(DARF),
  route: PrivateRoute,
}

// auth
const authRoutes = {
  path: '/conta',
  name: 'Conta',
  children: [
    {
      path: '/conta/token',
      name: 'Token',
      component: Token,
      route: Route,
    },
    {
      path: '/conta/entrar',
      name: 'Entrar',
      component: Login,
      route: Route,
    },
    {
      path: '/conta/sair',
      name: 'Sair',
      component: Logout,
      route: Route,
    },
    {
      path: '/conta/registrar',
      name: 'Registrar',
      component: Register,
      route: Route,
    },
    {
      path: '/conta/confirmar',
      name: 'Confirmar',
      component: Confirm,
      route: Route,
    },
    {
      path: '/conta/esqueci-senha',
      name: 'Esqueci a senha',
      component: ForgetPassword,
      route: Route,
    },
    {
      path: '/conta/nova-senha',
      name: 'Nova senha',
      component: NewPassword,
      route: Route,
    },
  ],
}

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = []

  routes = routes || []
  routes.forEach((item) => {
    flatRoutes.push(item)

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
    }
  })
  return flatRoutes
}

// All routes
const allRoutes = [
  rootRoute,
  authRoutes,
  dashboardRoutes,
  receitasRoutes.Index,
  receitasRoutes.AguardandoEmissao,
  receitasRoutes.Clientes,
  receitasRoutes.Lancamentos,
  receitasRoutes.NotasFiscais,
  despesasRoutes.Index,
  despesasRoutes.Folha,
  despesasRoutes.Fornecedores,
  despesasRoutes.Impostos,
  despesasRoutes.Lancamentos,
  minhaContaRoutes,
  perfilRoutes.Index,
  perfilRoutes.Automacao,
  perfilRoutes.CertificadosDigitais,
  perfilRoutes.CNAE,
  perfilRoutes.DadosCadastrais,
  perfilRoutes.Historico,
  perfilRoutes.Integracao,
  perfilRoutes.ProLabore,
  perfilRoutes.Socios,
  perfilRoutes.ContasCorrentes,
  operacoesRoutes.Index,
  operacoesRoutes.Pendentes,
  operacoesRoutes.AProcessar,
  operacoesRoutes.Processando,
  operacoesRoutes.Canceladas,
  operacoesRoutes.Sucesso,
  painelConfiguracoesRoutes.Index,
  painelConfiguracoesRoutes.Acessos,
  painelConfiguracoesRoutes.Automacao,
  painelConfiguracoesRoutes.IntegracaoJettax,
  painelConfiguracoesRoutes.IntegracaoMigrate,
  painelConfiguracoesRoutes.FatorR,
  painelFechamentoRoutes.Index,
  painelFechamentoRoutes.Faturamento,
  painelFechamentoRoutes.ProLabore,
  painelFechamentoRoutes.DAS,
  painelSociosRoutes.Index,
  painelSociosRoutes.Cadastro,
  painelSociosRoutes.ProLabore,
  regrasFiscaisMunicipalRoutes,
  regrasFiscaisFederalRoutes,
  declaracoesRoutes,
  empresaRoutes.Index,
  empresaRoutes.Clientes,
  empresaRoutes.NovosClientes,
  usersRoutes,
  contabilidadeRoutes.Index,
  contadorRoutes.EscritoriosContabeis,
  contadorRoutes.MeuEscritorio,
  painelComunicacoRoutes.Index,
  painelComunicacoRoutes.NotasFiscais,
  painelComunicacoRoutes.Impostos,
  painelComunicacoRoutes.ProLabore,
]

const staticRoutes = [gpsRoutes, darfRoutes, arquivoRoutes, downloadRoutes]

const authProtectedRoutes = [
  dashboardRoutes,
  receitasRoutes.Index,
  despesasRoutes.Index,
  contabilidadeRoutes.Index,
  empresaRoutes.Index,
  usersRoutes,
  declaracoesRoutes,
]

const allFlattenRoutes = flattenRoutes(allRoutes)
const allStaticRoutes = flattenRoutes(staticRoutes)

export { allRoutes, authProtectedRoutes, allFlattenRoutes, allStaticRoutes }
