// @flow
import React from 'react'
import { MAINTENANCE } from 'src/constants/config'

export default class Maintenance extends React.Component {
  render() {
    if (MAINTENANCE) {
      return (
        <div className="maintenance">
          <img src="/images/logos/logo-plataforma-lg.svg" height="90" alt="Logo" />
          <h4 className="text-uppercase text-danger mt-3">Estamos em manutenção no momento</h4>
          <p className="text-muted mt-3">
            Por favor, aguarde até que o serviço seja restabelecido.
          </p>
        </div>
      )
    }

    return this.props.children
  }
}
