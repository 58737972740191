import { ENVIRONMENT } from 'src/constants/config'

export const isHomolog = () => {
  return ENVIRONMENT === 'homolog'
}

export const isDevelopment = () => {
  return ENVIRONMENT === 'development'
}

export const isProduction = () => {
  return ENVIRONMENT === 'production'
}
