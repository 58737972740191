// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import packageJson from '../../package.json'
import { alertToast } from 'src/components/alert/ToastAlert'

global.appVersion = packageJson.version

const semVersionGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g)

  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b)
  }
  return false
}

function ButtonUpdateVersionComponent({ latestVersion }) {
  const clearCache = () => {
    console.log('Limpando o cache e forçando a atualização...')
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name)
      })
    }

    window.location.reload(true)
  }

  return (
    <div className="my-2">
      <p>
        Uma nova versão ({latestVersion}) da plataforma está disponível. É recomendável atualizar
        clicando no botão abaixo.
      </p>
      <Button color="info" block onClick={() => clearCache()}>
        Atualizar
      </Button>
    </div>
  )
}

class CacheBuster extends Component {
  _isMounted = false

  state = {
    loading: true,
    latestVersion: '',
    isLatestVersion: false,
  }

  componentDidMount() {
    this._isMounted = true
    this.getData()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData()
    }
  }

  refreshCacheAndReload = (version) => {
    alertToast(() => <ButtonUpdateVersionComponent latestVersion={version} />, 'default', {
      autoClose: false,
      position: 'bottom-right',
      closeButton: false,
      closeOnClick: false,
      toastId: 'alert-version',
    })
  }

  getData = () => {
    if (this._isMounted) {
      const myHeaders = new Headers()
      myHeaders.append('pragma', 'no-cache')
      myHeaders.append('cache-control', 'no-cache')
      const config = {
        method: 'GET',
        headers: myHeaders,
        cache: 'no-store',
      }

      fetch('/meta.json', config)
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version
          const currentVersion = global.appVersion
          const shouldForceRefresh = semVersionGreaterThan(latestVersion, currentVersion)
          if (shouldForceRefresh) {
            console.log(
              `Nova versão disponível (${latestVersion}). Atualize a página para limpar o cache.`
            )
            this.setState({ loading: false, isLatestVersion: false, latestVersion: latestVersion })
            this.refreshCacheAndReload(latestVersion)
          } else {
            // console.log(
            //   `Usando a última versão - ${latestVersion}. Não é necessário atualizar o cache.`
            // )
            this.setState({ loading: false, isLatestVersion: true, latestVersion: latestVersion })
          }
        })
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(CacheBuster)
