import * as storage from 'src/helpers/storage'
import * as CONSTANTS from 'src/constants/config'

const storageName = CONSTANTS.STORAGE_ALERTS_DATA

const localStateStorage = storage.getItem(storageName)

const initialStateDefault = {
  modalErrorAlert: {
    description: '',
    isOpen: false,
  },
}

export const TYPES = {
  TOGGLE_MODAL_ERROR_ALERT: 'togleModalAlert',
  RESET_MODAL_ERROR_ALERT: 'resetModalAlert',
}

export const initialState = localStateStorage || initialStateDefault

export const reducer = (state, action) => {
  let newState = state
  switch (action.type) {
    case TYPES.TOGGLE_MODAL_ERROR_ALERT:
      newState = {
        ...state,
        modalErrorAlert: {
          ...state.modalErrorAlert,
          description: action.payload,
          isOpen: !state.modalErrorAlert.isOpen,
        },
      }
      storage.setItem(storageName, newState)
      break
    case TYPES.RESET_MODAL_ERROR_ALERT:
      newState = {
        ...state,
        modalErrorAlert: {
          ...initialStateDefault.modalErrorAlert,
        },
      }
      storage.setItem(storageName, newState)
      break

    default:
      break
  }

  return newState
}
