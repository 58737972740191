// @flow
import * as storage from 'src/helpers/storage'
import * as CONSTANTS from 'src/constants/config'

const storageName = CONSTANTS.STORAGE_CACHE_DATA
const localStateStorage = storage.getItem(storageName)

export type CacheStateType = {
  ContasContabeis: [any],
}

const initialStateDefault: CacheStateType = {
  ContasContabeis: [],
}

export const TYPES = {
  SET_CACHE_CONTAS_CONTABEIS: 'cache.setCacheContasContabeis',
  CLEAR_CACHE: 'cache.clearCache',
}

export const initialState = localStateStorage || initialStateDefault

export const reducer = (state: CacheStateType, action: { type: string, payload: any }) => {
  let newState = state

  switch (action.type) {
    case TYPES.SET_CACHE_CONTAS_CONTABEIS:
      newState = { ...state, ContasContabeis: action.payload }

      storage.setItem(storageName, newState)
      break

    case TYPES.CLEAR_CACHE:
      storage.setItem(storageName, initialStateDefault)
      break

    default:
      break
  }

  return newState
}
