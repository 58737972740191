import pckg from '../../package.json'

// GTM
export const GTM = process.env.REACT_APP_GTM

// AUTH0
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE

// APP
export const APP_VERSION = pckg.version
export const ENVIRONMENT = process.env.REACT_APP_CUSTOM_ENV || 'development'
export const MAINTENANCE = process.env.REACT_APP_MAINTENANCE

// White Label
export const WHITE_LABEL = process.env.REACT_APP_WHITE_LABEL
export const WHITE_LABEL_TITLE = process.env.REACT_APP_WHITE_LABEL_TITLE
export const WHITE_LABEL_DESCRIPTION = process.env.REACT_APP_WHITE_LABEL_DESCRIPTION
export const WHITE_LABEL_COLOR = process.env.REACT_APP_WHITE_LABEL_COLOR

// STORAGE
export const STORAGE_NAME = WHITE_LABEL
export const STORAGE_USER_DATA = 'user'
export const STORAGE_CACHE_DATA = 'cache'
export const STORAGE_ALERTS_DATA = 'alerts'
export const STORAGE_COMPANY_DATA = 'company'
export const STORAGE_PROFILE_DATA = 'profile'
export const STORAGE_OPERACOES_DATA = 'operacoes'
export const STORAGE_PAINEL_FECHAMENTO = 'painelFechamento'
export const STORAGE_PAINEL_COMUNICACAO = 'painelComunicacao'

// STORAGE PERSIST
export const STORAGE_PERSIST_VERSION = '1'
export const STORAGE_PERSIST_PREFERENCES_DATA = 'preferences'

// TOKEN EXPIRE IN MINUTES
export const TIME_IDLE_LOGOUT = 60

// CUSTOM EVENTS
export const CUSTOM_EVENTS_LOADING = 'ftLoading'

// URLS
export const URL_SITE = process.env.REACT_APP_WHITE_LABEL_URL_SITE
export const LINK_AJUDA = process.env.REACT_APP_WHITE_LABEL_LINK_AJUDA
export const LINK_TERMO = process.env.REACT_APP_WHITE_LABEL_LINK_TERMO
export const URL_API = process.env.REACT_APP_URL_API
export const URL_LOGOUT = '/conta/sair'

// MUNICIPIOS
export const ENUM_ID_MUNICIPIO = {
  SP: 3829,
}

export const COD_PAIS_BRASIL = 1058

// ROLES
export const ROLE = {
  INTEGRACAO: 0,
  ADMINISTRADOR: 1,
  CONTADOR: 2,
  EMPRESA: 3,
  FUNCIONARIO: 4,
  ROOT: 5,
}

// STATUS NFE
export const STATUS_NFE = {
  ATIVA: {
    code: 1,
    description: 'Ativa',
    color: 'light',
  },
  CANCELADA: {
    code: 2,
    description: 'Cancelada',
    color: 'danger',
  },
  SUBSTITUIDA: {
    code: 3,
    description: 'Substituida',
    color: 'warning',
  },
}

// Status Automacoes
export const STATUS_AUTOMACOES = {
  APROCESSAR: null,
  EXECUTANDO: 1,
  SUCESSO: 2,
  ERRO: 3,
  CANCELADO: 4,
}

// STATUS NFE FILA
export const STATUS_NFE_FILA = {
  NOVA: {
    code: 0,
    description: 'A faturar',
    color: 'light',
  },
  ERRODEFATURAMENTO: {
    code: 1,
    description: 'Erro no Faturamento',
    color: 'danger',
  },
  FATURADA: {
    code: 2,
    description: 'Faturado',
    color: 'success',
  },
  AGENDAMENTO: {
    code: 3,
    description: 'Agendada',
    color: 'warning',
  },
  CANCELADOS: {
    code: 4,
    description: 'Cancelado',
    color: 'danger',
  },
}

// STATUS IMPOSTOS
export const STATUS_IMPOSTOS = {
  BAIXADO: {
    code: 1,
    description: 'Pago',
    color: 'info',
  },
  ABERTO: {
    code: 2,
    description: 'A Pagar',
    color: 'light',
  },
}

// STATUS A RECEBER
export const STATUS_A_RECEBER = {
  BAIXADO: {
    code: 1,
    description: 'Baixado',
    color: 'info',
  },
  ABERTO: {
    code: 2,
    description: 'Em aberto',
    color: 'light',
  },
}

export const STATUS_IMPOSTOS_LIST = [
  {
    Value: 1,
    Text: 'Pago',
    color: 'info',
  },
  {
    Value: 2,
    Text: 'A Pagar',
    color: 'light',
  },
]

export const STATUS_DESPESA_IMPOSTOS = {
  AGUARDANDO: {
    code: 0,
    description: 'Aguardando',
    color: 'light',
  },
  FINALIZADO: {
    code: 2,
    description: 'Processado',
    color: 'info',
  },
  ERRO: {
    code: 3,
    description: 'Erro',
    color: 'danger',
  },
}

// Mask Types
export const MASK_TYPES = {
  ZIPCODE: 'cep',
  RG: 'rg',
  CPF: 'cpf',
  CNPJ: 'cnpj',
  DATE: 'date',
  DATE_MES_ANO: 'date-mes-ano',
  BIRTHDATE: 'birthDate',
  CELLPHONE: 'cellphone',
  EMAIL: 'email',
  AGENCIA: 'agencia',
  CONTACORRENTE: 'conta-corrente',
}

export const MASK = {
  CELLPHONE: [
    '(',
    /[1-9]/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  CNPJ: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  DATE: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  CPF: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  ZIPCODE: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
}

// Format
export const FORMAT_DEFINITIONS = {
  DATE: 'dd/MM/yyyy',
}

export const OPERACOES_OPTIONS = {
  PROLABORE: 'Prolabore',
  PROLABORE_GPS: 'GPS',
  PROLABORE_GPS_RECALCULAR: 'Recalcular GPS',
  PROLABORE_GPS_GERAR: 'Gerar GPS',
  PROLABORE_GPS_COMPROVANTE: 'Comprovante de Pagamento',
  PROLABORE_HONORARIOS: 'Honorários',
}

// Operacoes Tipos
export const OPERACOES_TIPO = {
  PROLABORE: {
    DESCRIPTION: OPERACOES_OPTIONS.PROLABORE,
    OPTIONS: [
      {
        DESCRIPTION: OPERACOES_OPTIONS.PROLABORE_GPS,
        OPTIONS: [
          {
            DESCRIPTION: OPERACOES_OPTIONS.PROLABORE_GPS_RECALCULAR,
          },
          {
            DESCRIPTION: OPERACOES_OPTIONS.PROLABORE_GPS_GERAR,
          },
          {
            DESCRIPTION: OPERACOES_OPTIONS.PROLABORE_GPS_COMPROVANTE,
          },
        ],
      },
      {
        DESCRIPTION: OPERACOES_OPTIONS.PROLABORE_HONORARIOS,
        OPTIONS: [],
      },
    ],
  },
  DAS: {
    DESCRIPTION: 'DAS',
    OPTIONS: [],
  },
  NOTA_FISCAL: {
    DESCRIPTION: 'Nota Fiscal',
    OPTIONS: [],
  },
  PIS_COFINS: {
    DESCRIPTION: 'PIS/COFINS',
    OPTIONS: [],
  },
  IRPJ_CSLL: {
    DESCRIPTION: 'IRPJ/CSLL',
    OPTIONS: [],
  },
}

// STATUS IMPOSTOS
export const TIPO_DESPESAS = {
  INSS: {
    ID: 41,
    description: 'INSS',
  },
  IRRF: {
    ID: 32,
    description: 'IRRF',
  },
  PROLABORE: {
    ID: 40,
    description: 'Pró-labore',
  },
  DAS: {
    ID: 27,
    description: 'DAS Simples',
  },
  DASMEI: {
    ID: 28,
    description: 'DAS MEI',
  },
}

// Sexo
export const ENUM_SEXO = [
  {
    Codigo: 1,
    Descricao: 'Feminino',
  },
  {
    Codigo: 2,
    Descricao: 'Masculino',
  },
]

// Estado Civil
export const ENUM_ESTADO_CIVIL = [
  {
    Codigo: 1,
    Descricao: 'Solteiro(a)',
  },
  {
    Codigo: 2,
    Descricao: 'Casado(a)',
  },
  {
    Codigo: 3,
    Descricao: 'Divorciado(a)',
  },
  {
    Codigo: 4,
    Descricao: 'Viúvo(a)',
  },
  {
    Codigo: 5,
    Descricao: 'Desquitado(a)/Separado(a)',
  },
]

// Estado Civil
export const ENUM_RACA = [
  {
    Codigo: 1,
    Descricao: 'Indígena',
  },
  {
    Codigo: 2,
    Descricao: 'Branca',
  },
  {
    Codigo: 3,
    Descricao: 'Negra',
  },
  {
    Codigo: 4,
    Descricao: 'Amarela',
  },
  {
    Codigo: 5,
    Descricao: 'Parda',
  },
  {
    Codigo: 6,
    Descricao: 'Não informada',
  },
]

// Condição de ingresso ao Brasil
export const ENUM_INGRESSO_BREX = [
  {
    Codigo: 1,
    Descricao: 'Visto permanente',
  },
  {
    Codigo: 2,
    Descricao: 'Visto temporário',
  },
  {
    Codigo: 3,
    Descricao: 'Asilado',
  },
  {
    Codigo: 4,
    Descricao: 'Refugiado',
  },
  {
    Codigo: 5,
    Descricao: 'Solicitante de Refúgio',
  },
  {
    Codigo: 6,
    Descricao: 'Residente fora do Brasil',
  },
  {
    Codigo: 7,
    Descricao: 'Deficiente físico e com mais de 51 anos',
  },
  {
    Codigo: 8,
    Descricao: 'Com residência provisória e anistiado, em situação irregular',
  },
  {
    Codigo: 9,
    Descricao: 'Permanência no Brasil em razão de filhos ou cônjuge brasileiros',
  },
  {
    Codigo: 10,
    Descricao: 'Beneficiado pelo acordo entre países do Mercosul',
  },
  {
    Codigo: 11,
    Descricao:
      'Dependente de agente diplomático e/ou consular de países que mantém convênio de reciprocidade para o exercício de atividade remunerada no Brasil',
  },
  {
    Codigo: 12,
    Descricao:
      'Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil e a República Portuguesa',
  },
]

export const ENUM_GRAU_INSTRUCAO = [
  {
    Codigo: 1,
    Descricao: 'Analfabeto, inclusive o que, embora tenha recebido instrução, não se alfabetizou',
  },
  {
    Codigo: 2,
    Descricao:
      'Até o 5º ano incompleto do Ensino Fundamental (antiga 4ª série) ou que se tenha alfabetizado sem ter frequentado escola regular',
  },
  {
    Codigo: 3,
    Descricao: '5º ano completo do Ensino Fundamental',
  },
  {
    Codigo: 4,
    Descricao: 'Do 6º ao 9º ano do Ensino Fundamental incompleto (antiga 5ª a 8ª série)',
  },
  {
    Codigo: 5,
    Descricao: 'Ensino Fundamental Completo',
  },
  {
    Codigo: 6,
    Descricao: 'Ensino Médio incompleto',
  },
  {
    Codigo: 7,
    Descricao: 'Ensino Médio completo',
  },
  {
    Codigo: 8,
    Descricao: 'Educação Superior incompleta',
  },
  {
    Codigo: 9,
    Descricao: 'Educação Superior completa',
  },
  {
    Codigo: 10,
    Descricao: 'Pós-Graduação completa',
  },
  {
    Codigo: 11,
    Descricao: 'Mestrado completo',
  },
  {
    Codigo: 12,
    Descricao: 'Doutorado completo',
  },
]

// UF
export const ENUM_UF = [
  {
    Codigo: '35',
    Nome: 'SP',
  },
  {
    Codigo: 2,
    Nome: 'DESCOBRIR',
  },
  {
    Codigo: 3,
    Nome: 'DESCOBRIR',
  },
  {
    Codigo: 4,
    Nome: 'DESCOBRIR',
  },
  {
    Codigo: 5,
    Nome: 'DESCOBRIR',
  },
  {
    Codigo: 6,
    Nome: 'Negra',
  },
  {
    Codigo: 7,
    Nome: 'DESCOBRIR',
  },
]

// Categoria Empresa
export const ENUM_CATEGORIA_EMPRESA = [
  {
    Selected: false,
    Text: 'Simples',
    Value: 1,
  },
  {
    Selected: false,
    Text: 'Grupos',
    Value: 2,
  },
  {
    Selected: false,
    Text: 'Ex-Cliente',
    Value: 37,
  },
  {
    Selected: false,
    Text: 'Encerrada',
    Value: 38,
  },
]

export const ENUM_CATEGORIA_EMPRESA_OBJECT = {
  Simples: 1,
  Grupos: 2,
  ExCliente: 37,
  Encerrada: 38,
}

export const STATUS_CATEGORIA_EMPRESA_OBJECT = {
  SIMPLES: {
    code: 1,
    description: 'Simples',
    color: 'light',
  },
  GRUPOS: {
    code: 2,
    description: 'Grupos',
    color: 'dark',
  },
  EX_CLIENTE: {
    code: 37,
    description: 'Ex-Cliente',
    color: 'warning',
  },
  ENCERRADA: {
    code: 38,
    description: 'Encerrada',
    color: 'danger',
  },
}

// Perfil Usuario
export const ENUM_PERFIL_USUARIO = [
  {
    Codigo: 3,
    Nome: 'Empresa',
    Nivel: 1,
  },
  {
    Codigo: 2,
    Nome: 'Contador',
    Nivel: 2,
  },
  {
    Codigo: 1,
    Nome: 'Administrador',
    Nivel: 3,
  },
  {
    Codigo: 5,
    Nome: 'Root',
    Nivel: 4,
  },
]

// Usuario Ativo
export const ENUM_USUARIO_ATIVO = [
  {
    Codigo: true,
    Nome: 'Sim',
  },
  {
    Codigo: false,
    Nome: 'Não',
  },
]

// Forma Calculo PCC
export const ENUM_CALCULO_PCC = [
  {
    Selected: false,
    Text: 'Competência',
    Value: '0',
  },
  {
    Selected: false,
    Text: 'Regime de Caixa',
    Value: '1',
  },
]

// Classificação Tributária
export const ENUM_CLASSIFICACAO_TRIBUTARIA = [
  {
    Selected: false,
    Text: 'Anexos: 1, 2, 3 e 5',
    Value: 1,
  },
  {
    Selected: false,
    Text: 'Anexo 4',
    Value: 2,
  },
  {
    Selected: false,
    Text: 'Anexos: 1, 2, 3, 4 e 5',
    Value: 3,
  },
]

// Anexos Simples Nacional
export const ENUM_ANEXO_SIMPLES = [
  {
    Selected: false,
    Text: 'I',
    Value: 1,
  },
  {
    Selected: false,
    Text: 'II',
    Value: 2,
  },
  {
    Selected: false,
    Text: 'III',
    Value: 3,
  },
  {
    Selected: false,
    Text: 'IV',
    Value: 4,
  },
  {
    Selected: false,
    Text: 'V',
    Value: 5,
  },
]

// Indicativo da Situação da Pessoa Jurídica
export const ENUM_SITUACAO_PJ = [
  {
    Selected: false,
    Text: 'Situação Normal',
    Value: 0,
  },
  {
    Selected: false,
    Text: 'Extinção',
    Value: 1,
  },
  {
    Selected: false,
    Text: 'Fusão',
    Value: 2,
  },

  {
    Selected: false,
    Text: 'Cisão',
    Value: 3,
  },
  {
    Selected: false,
    Text: 'Incorporação',
    Value: 4,
  },
]

// Porte
export const ENUM_PORTE = [
  {
    Text: 'ME',
    Value: 20,
  },
  {
    Text: 'EPP',
    Value: 21,
  },
  {
    Text: 'DEMAIS',
    Value: 99,
  },
]

// Regime Tributario
export const ENUM_REGIME_TRIBUTARIO = [
  {
    Selected: false,
    Text: 'Simples',
    Value: 1,
  },
  {
    Selected: false,
    Text: 'Presumido',
    Value: 2,
  },
  {
    Selected: false,
    Text: 'Real Trimestral',
    Value: 3,
  },
  {
    Selected: false,
    Text: 'Real Mensal',
    Value: 4,
  },
  {
    Selected: false,
    Text: 'Isenta',
    Value: 5,
  },
  {
    Selected: false,
    Text: 'Mei',
    Value: 6,
  },
  {
    Selected: false,
    Text: 'Pessoa Física/Estrangeiro',
    Value: 7,
  },
  {
    Selected: false,
    Text: 'Não Informado',
    Value: 8,
  },
]

export const ENUM_REGIME_TRIBUTARIO_OBJECT = {
  SIMPLES: 1,
  PRESUMIDO: 2,
  REAL_TRIMESTRAL: 3,
  REAL_MENSAL: 4,
  ISENTA: 5,
  MEI: 6,
  PESSOA_FISICA_OU_ESTRANGEIRO: 7,
  NAO_INFORMADO: 8,
}

// Tipo Pessoa
export const ENUM_TIPO_PESSOA = [
  {
    Selected: false,
    Text: 'Pessoa Física',
    Value: '1',
  },
  {
    Selected: false,
    Text: 'Pessoa Jurídica',
    Value: '2',
  },
  {
    Selected: false,
    Text: 'Estrangeiro',
    Value: '3',
  },
]

export const STATUS_REGRAS_FISCAIS = [
  {
    Text: 'A Processar',
    Value: 0,
  },
  {
    Text: 'Executando',
    Value: 1,
  },
  {
    Text: 'Sucesso',
    Value: 2,
  },
  {
    Text: 'Erro',
    Value: 3,
  },
  {
    Text: 'Inconsistência',
    Value: 4,
  },
  {
    Text: 'Cancelado',
    Value: 5,
  },
]

export const STATUS_REGRAS_FISCAIS_OBJECT = {
  APROCESSAR: {
    code: 0,
    description: 'A Processar',
    color: 'light',
  },
  EXECUTANDO: {
    code: 1,
    description: 'Executando',
    color: 'dark',
  },
  SUCESSO: {
    code: 2,
    description: 'Sucesso',
    color: 'info',
  },
  ERRO: {
    code: 3,
    description: 'Erro',
    color: 'danger',
  },
  INCONSISTENCIA: {
    code: 4,
    description: 'Inconsistência',
    color: 'danger',
  },
  CANCELADO: {
    code: 5,
    description: 'Cancelado',
    color: 'danger',
  },
}

export const STATUS_REGRAS_FISCAIS_ITEM_IMPORTACAO_OBJECT = {
  INSERIDO: {
    code: 1,
    description: 'Inserido',
    color: 'light',
  },
  ATUALIZADO: {
    code: 2,
    description: 'Atualizado',
    color: 'dark',
  },
  EXCLUIDO: {
    code: 3,
    description: 'Excluído',
    color: 'info',
  },
  ERRO: {
    code: 4,
    description: 'Erro',
    color: 'danger',
  },
  SEMACAO: {
    code: 5,
    description: 'Sem Ação',
    color: 'danger',
  },
}

export const STATUS_ACTIVE = [
  {
    Text: 'Sim',
    Value: true,
  },
  {
    Text: 'Não',
    Value: false,
  },
]

export const STATUS_COMUNICACAO = [
  {
    Text: 'Criada',
    Value: 'CRIADA',
  },
  {
    Text: 'Lida',
    Value: 'LIDA',
  },
  {
    Text: 'Recebida',
    Value: 'RECEBIDA',
  },
]

export const STATUS_FATURAMENTO = [
  {
    Text: 'Faturada',
    Value: 1,
  },
  {
    Text: 'Cancelada',
    Value: 2,
  },
]

export const ENUM_TIPO_PESSOA_OBJECT = {
  PESSOA_FISICA: 1,
  PESSOA_JURIDICA: 2,
  PESSOA_ESTRANGEIRA: 3,
  PESSOA_JURIDICA_ALT: 0,
}

// Tipo Prolabore
export const ENUM_TIPO_PROLABORE = [
  {
    Selected: false,
    Text: 'Salários Mínimos',
    Value: 1,
  },
  {
    Selected: false,
    Text: 'Valor Fixo (R$)',
    Value: 2,
  },
  {
    Selected: false,
    Text: 'Percentual do Faturamento (%)',
    Value: 3,
  },
]

export const ENUM_TIPO_PROLABORE_OBJECT = {
  SALARIOS_MINIMOS: 1,
  VALOR_FIXO: 2,
  PERCENTUAL_FATURAMENTO: 3,
}

// Tipo Certificado
export const ENUM_TIPO_CERTIFICADO = [
  {
    Text: 'E-CNPJ',
    Value: 1,
  },
  {
    Text: 'E-CPF',
    Value: 2,
  },
]

export const ENUM_TIPO_CERTIFICADO_OBJECT = {
  ECNPJ: 1,
  ECPF: 2,
}

// Qualificacao
export const ENUM_QUALIFICACAO_PERFIL_PJ = [
  {
    Selected: false,
    Text: 'Não Selecionado',
    Value: '0',
  },
  {
    Selected: false,
    Text: 'Financeira',
    Value: '1',
  },
  {
    Selected: false,
    Text: 'Sociedade Seguradora',
    Value: '2',
  },
  {
    Selected: false,
    Text: 'Corretora Autônoma de Seguros',
    Value: '3',
  },
  {
    Selected: false,
    Text: 'Cooperativa de Crédito',
    Value: '4',
  },
  {
    Selected: false,
    Text: 'Entidade Fechada',
    Value: '5',
  },
  {
    Selected: false,
    Text: 'Sociedade Cooperativa',
    Value: '6',
  },
  {
    Selected: false,
    Text: 'PJ em Geral',
    Value: '7',
  },
  {
    Selected: false,
    Text: 'Sociedade Cooperativa de Produção Agropecuária',
    Value: '8',
  },
  {
    Selected: false,
    Text: 'Autarquia',
    Value: '9',
  },
  {
    Selected: false,
    Text: 'Orgão Publico - Administração Direta',
    Value: '10',
  },
  {
    Selected: false,
    Text: 'Empresa Pública ou Sociedade de Economia Mista',
    Value: '11',
  },
  {
    Selected: false,
    Text: 'ME',
    Value: '20',
  },
  {
    Selected: false,
    Text: 'EPP',
    Value: '21',
  },
]

// QualificacaoPJ
export const ENUM_QUALIFICACAO_PERFIL = [
  {
    ID: 1,
    Tipo: 0,
    Codigo: '5',
    Nome: 'ADMINISTRADOR',
    Descricao: null,
  },
  {
    ID: 2,
    Tipo: 0,
    Codigo: '22',
    Nome: 'SOCIO',
    Descricao: null,
  },
  {
    ID: 3,
    Tipo: 0,
    Codigo: '29',
    Nome: 'SOCIO INCAPAZ OU RELAT. INCAPAZ (EXCETO MENOR)',
    Descricao: null,
  },
  {
    ID: 4,
    Tipo: 0,
    Codigo: '30',
    Nome: 'SOCIO MENOR (ASSISTIDO/REPRESENTADO)',
    Descricao: null,
  },
  {
    ID: 5,
    Tipo: 0,
    Codigo: '37',
    Nome: 'SOCIO PESSOA JURIDICA DOMICILIADO NO EXTERIOR',
    Descricao: null,
  },
  {
    ID: 6,
    Tipo: 0,
    Codigo: '38',
    Nome: 'SOCIO PESSOA FISICA RESIDENTE NO EXTERIOR',
    Descricao: null,
  },
  {
    ID: 7,
    Tipo: 0,
    Codigo: '49',
    Nome: 'SOCIO-ADMINISTRADOR',
    Descricao: null,
  },
  {
    ID: 8,
    Tipo: 0,
    Codigo: '99',
    Nome: 'REPRESENTANTE',
    Descricao: null,
  },
  {
    ID: 9,
    Tipo: 0,
    Codigo: '98',
    Nome: 'PRESIDENTE',
    Descricao: null,
  },
  {
    ID: 10,
    Tipo: 0,
    Codigo: '97',
    Nome: 'DIRETOR',
    Descricao: null,
  },
  {
    ID: 11,
    Tipo: 0,
    Codigo: '96',
    Nome: 'TESOUREIRO',
    Descricao: null,
  },
  {
    ID: 12,
    Tipo: 0,
    Codigo: '95',
    Nome: 'VICE-PRESIDENTE',
    Descricao: null,
  },
  {
    ID: 13,
    Tipo: 0,
    Codigo: '94',
    Nome: 'SECRETARIO',
    Descricao: null,
  },
  {
    ID: 14,
    Tipo: 1,
    Codigo: '1',
    Nome: 'FINANCEIRA',
    Descricao: 'FINANCEIRA',
  },
  {
    ID: 15,
    Tipo: 1,
    Codigo: '2',
    Nome: 'SOCIEDADE SEGURADORA',
    Descricao:
      'SOCIEDADE SEGURADORA, DE CAPTALIZAÇÃO OU ENTIDADE ABERTA DE PREVIDENCIA COMPLEMENTAR (COM FINS LUCRATIVOS)',
  },
  {
    ID: 16,
    Tipo: 1,
    Codigo: '3',
    Nome: 'CORRETORA AUTONOMA DE SEGURO\t',
    Descricao: 'CORRETORA AUTONOMA DE SEGURO\t',
  },
  {
    ID: 17,
    Tipo: 1,
    Codigo: '4',
    Nome: 'COOPERATIVA DE CREDITO',
    Descricao: 'COOPERATIVA DE CREDITO',
  },
  {
    ID: 18,
    Tipo: 1,
    Codigo: '5',
    Nome: 'ENTIDADE FECHADA',
    Descricao:
      'ENTIDADE FECHADA DE PREVIDENCIA COMPLEMENTAR OU ENTIDADE ABERTA DE PREVIDENCIA COMPLEMENTAR (SEM FINS LUCRATIVOS)',
  },
  {
    ID: 19,
    Tipo: 1,
    Codigo: '6',
    Nome: 'SOCIEDADE COOPERATIVA',
    Descricao: 'SOCIEDADE COOPERATIVA',
  },
  {
    ID: 20,
    Tipo: 1,
    Codigo: '7',
    Nome: 'PJ EM GERAL',
    Descricao: 'PJ EM GERAL',
  },
  {
    ID: 21,
    Tipo: 1,
    Codigo: '8',
    Nome: 'SOCIEDADE COOPERATIVA PRODUCAO AGROPECUARIA',
    Descricao: 'SOCIEDADE COOPERATIVA PRODUCAO AGROPECUARIA',
  },
  {
    ID: 22,
    Tipo: 1,
    Codigo: '9',
    Nome: 'AUTARQUIA',
    Descricao: 'AUTARQUIA',
  },
  {
    ID: 23,
    Tipo: 1,
    Codigo: '10',
    Nome: 'ORGAO PUBLICO ADMINISTRACAO DIRETA',
    Descricao: 'ORGAO PUBLICO ADMINISTRACAO DIRETA',
  },
  {
    ID: 24,
    Tipo: 1,
    Codigo: '11',
    Nome: 'EMPRESA PUBLICA OU SOCIEDADE ECONOMIAMISTA',
    Descricao: 'EMPRESA PUBLICA OU SOCIEDADE ECONOMIAMISTA',
  },
  {
    ID: 25,
    Tipo: 1,
    Codigo: '20',
    Nome: 'ME',
    Descricao: 'ME',
  },
  {
    ID: 26,
    Tipo: 1,
    Codigo: '21',
    Nome: 'EPP',
    Descricao: 'EPP',
  },
]

export const ENUM_TIPO_CONTAS_CORRENTES = [
  {
    ID: 1,
    Text: 'Normal',
  },
  {
    ID: 2,
    Text: 'Aplicações',
  },
  {
    ID: 6,
    Text: 'Cartão de Crédito',
  },
]

export const ENUM_TIPO_CONTAS_CONTABEIS = [
  {
    ID: 0,
    Text: 'Ambas',
  },
  {
    ID: 1,
    Text: 'Sintéticas',
  },
  {
    ID: 2,
    Text: 'Analiticas',
  },
]

export const ENUM_STATUS_FINANCEIRO = {
  BAIXADO: 1,
  ABERTO: 2,
  ESTORNO: 3,
}
