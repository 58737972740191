import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import * as UserContext from './context/UserContext'
import * as PreferencesContext from './context/PreferencesContext'
import * as AlertsContext from './context/AlertsContext'
import * as QueryContext from './context/QueryContext'
import * as CompanyContext from './context/CompanyContext'
import * as ProfileContext from './context/ProfileContext'
import * as OperacoesContext from './context/OperacoesContext'
import * as PainelFechamentoContext from './context/PainelFechamentoContext'
import * as PainelComunicacaoContext from './context/PainelComunicacaoContext'
import * as CacheContext from './context/CacheContext'

const reducer = (
  { user, preferences, alerts, query, company, profile, operacoes, painelFechamento, painelComunicacao, cache },
  action
) => {
  // middleware goes here, i.e calling analytics service, etc
  return {
    user: UserContext.reducer(user, action),
    preferences: PreferencesContext.reducer(preferences, action),
    alerts: AlertsContext.reducer(alerts, action),
    query: QueryContext.reducer(query, action),
    company: CompanyContext.reducer(company, action),
    profile: ProfileContext.reducer(profile, action),
    operacoes: OperacoesContext.reducer(operacoes, action),
    painelFechamento: PainelFechamentoContext.reducer(painelFechamento, action),
    painelComunicacao: PainelComunicacaoContext.reducer(painelComunicacao, action),
    cache: CacheContext.reducer(cache, action),
  }
}

const initialState = {
  user: UserContext.initialState,
  preferences: PreferencesContext.initialState,
  alerts: AlertsContext.initialState,
  query: QueryContext.initialState,
  company: CompanyContext.initialState,
  profile: ProfileContext.initialState,
  operacoes: OperacoesContext.initialState,
  painelFechamento: PainelFechamentoContext.initialState,
  painelComunicacao: PainelComunicacaoContext.initialState,
  cache: CacheContext.initialState,
}

export const StateContext = createContext()

export const StateProvider = ({ children }) => {
  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  )
}

StateProvider.propTypes = {
  /**
   * @return {React.Node}
   */
  children: PropTypes.node.isRequired,

  /**
   * Object containing initial state value.
   */
  // initialState: PropTypes.shape({}).isRequired,

  /**
   *
   * @param {object} state
   * @param {object} action
   */
  // reducer: PropTypes.func.isRequired,
}

// eslint-disable-next-line react-hooks/rules-of-hooks
export const getState = () => useContext(StateContext)
