// @flow
import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { StateContext } from 'src/state'
import * as AlertsContext from 'src/context/AlertsContext'

type Props = {
  titleAlert: string,
  descriptionAlert: string,
  descriptionAlert: string,
  color: 'danger' | 'success' | 'warning',
  toggle: (any) => void,
  show: boolean,
  iconType?: 'warning' | 'danger',
}

export class ModalAlert extends Component<Props> {
  static contextType = StateContext

  componentDidMount = async () => {
    alertModalReset(this.context)
  }

  toggle = async () => {
    alertModal(this.context, '')
  }

  gerIconName = () => {
    switch (this.props.iconType) {
      case 'warning':
        return 'mdi-alert-octagon'

      default:
        return 'mdi-emoticon-sad-outline'
    }
  }

  render() {
    const [{ alerts }] = this.context
    const { modalErrorAlert } = alerts

    return (
      modalErrorAlert.isOpen && (
        <Modal
          isOpen={modalErrorAlert.isOpen}
          toggle={this.toggle}
          size="sm"
          className={this.props.className}>
          <ModalHeader className="bg-white mb-0 pb-0" toggle={this.toggle} />
          <ModalBody className="text-center">
            <i
              className={`${
                this.props.color ? `text-${this.props.color}` : 'text-danger'
              } display-2 mdi ${this.gerIconName()}`}></i>
            {this.props.titleAlert && <h3>{this.props.titleAlert}</h3>}
            {!this.props.titleAlert && <h3>Ops!</h3>}

            {modalErrorAlert.description && (
              <p className="mb-4 font-weight-normal">{modalErrorAlert.description}</p>
            )}
            {!modalErrorAlert.description && (
              <p className="mb-4 font-weight-normal">
                Ocorreu um erro. <br />
                Tente novamente mais tarde.
              </p>
            )}
            <div className="text-center mb-3">
              <Button color="primary" onClick={this.toggle}>
                FECHAR
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )
    )
  }
}

export async function alertModal(context, msg) {
  const [, dispatch] = context
  await dispatch({
    type: AlertsContext.TYPES.TOGGLE_MODAL_ERROR_ALERT,
    payload: msg,
  })
}

export async function alertModalReset(context) {
  const [, dispatch] = context
  await dispatch({ type: AlertsContext.TYPES.RESET_MODAL_ERROR_ALERT })
}
