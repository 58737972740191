import * as storage from 'src/helpers/storage'
import * as CONSTANTS from 'src/constants/config'

const storageName = CONSTANTS.STORAGE_PROFILE_DATA
const localStateStorage = storage.getItem(storageName)
const initialStateDefault = {
  data: {
    Empresa: {
      PerfilAtual: {
        NomeFantasia: '',
      },
    },
    RegimeTributario: [],
    ListaPerfis: [],
  },
  Socios: [],
}

export const TYPES = {
  SET_DATA: 'profile.setData',
  SET_EMPRESA: 'profile.setEmpresa',
  SET_REGIME_TRIBUTARIO: 'profile.setRegimeTributario',
  SET_SOCIOS: 'profile.setSocios',
}

export const initialState = localStateStorage || initialStateDefault

export const reducer = (state, action) => {
  let newState = state
  switch (action.type) {
    case TYPES.SET_DATA:
      newState = {
        ...state,
        data: {
          ...action.payload,
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_EMPRESA:
      newState = {
        ...state,
        data: {
          ...state,
          Empresa: {
            ...action.payload,
          },
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_REGIME_TRIBUTARIO:
      newState = {
        ...state,
        data: {
          ...state,
          RegimeTributario: [...action.payload],
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_SOCIOS:
      newState = {
        ...state,
        Socios: [...action.payload],
      }
      storage.setItem(storageName, newState)
      break

    default:
      break
  }

  return newState
}
