import * as storage from '../helpers/storage'
import * as CONSTANTS from '../constants'

const storageName = CONSTANTS.STORAGE_COMPANY_DATA
const localStateStorage = storage.getItem(storageName)
const initialStateDefault = {
  data: {
    ID: null,
    Apelido: '',
    IDRegime: null,
    IDCategoria: null,
    IDMunicipio: null,
    IDEscritorioContabil: null,
    Identificacao: '',
  },
}

export const TYPES = {
  SET_COMPANY: 'setCompany',
}

export const initialState = localStateStorage || initialStateDefault

export const reducer = (state, action) => {
  let newState = state
  // console.log(state)
  // console.log(action)
  // console.log(storageName)
  // console.log(storageName)
  switch (action.type) {
    case TYPES.SET_COMPANY:
      newState = {
        ...state,
        data: action.payload,
      }
      storage.setItem(storageName, newState)
      break

    default:
      break
  }

  return newState
}
