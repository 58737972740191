import * as storage from 'src/helpers/storage'
import * as CONSTANTS from 'src/constants/config'

const storageName = CONSTANTS.STORAGE_USER_DATA
const localStateStorage = storage.getItem(storageName)
const initialStateDefault = {
  data: {
    FullName: '',
    Token: '',
    login: '',
    IDFuncionario: null,
    RotaInicial: '',
    IDPerfilAcesso: null,
    AccessToken: '',
    Firebase: {
      stsTokenManager: {
        accessToken: '',
      },
    },
    validacao: {
      Valido: false,
      Mensagens: [],
    },
  },
  logged: false,
}

export const TYPES = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  SET_TOKEN: 'setToken',
  SET_FIREBASE_TOKEN: 'setFirebaseToken',
  SET_ACCESS_TOKEN: 'setAccessToken',
}

export const initialState = localStateStorage || initialStateDefault

export const reducer = (state, action) => {
  let newState = state
  switch (action.type) {
    case TYPES.LOGIN:
      newState = {
        ...state,
        logged: true,
        data: action.payload,
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_TOKEN:
      newState = {
        ...state,
        // logged: true,
        data: {
          ...state.data,
          Token: action.payload,
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_FIREBASE_TOKEN:
      newState = {
        ...state,
        // logged: true,
        data: {
          ...state.data,
          Firebase: {
            ...state.data.Firebase,
            stsTokenManager: {
              ...state.data.Firebase.stsTokenManager,
              accessToken: action.payload,
            },
          },
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.SET_ACCESS_TOKEN:
      newState = {
        ...state,
        data: {
          ...state.data,
          AccessToken: action.payload,
        },
      }
      storage.setItem(storageName, newState)
      break

    case TYPES.LOGOUT:
      newState = {
        ...initialStateDefault,
        logged: false,
      }
      break

    default:
      break
  }

  return newState
}
