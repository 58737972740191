import * as CONSTANTS from 'src/constants/config'
import * as jsonParse from 'src/helpers/jsonParse'

const version = CONSTANTS.STORAGE_PERSIST_VERSION

const compositeName = (name) => {
  return `${CONSTANTS.STORAGE_NAME}-${version}-${name}`.toUpperCase()
}

export const setItem = (name, value) => {
  localStorage.setItem(compositeName(name), JSON.stringify(value))
}

export const getItem = (name) => {
  const localData = localStorage.getItem(compositeName(name))

  if (localData && jsonParse.isValid(localData)) {
    return JSON.parse(localData)
  }

  if (localData === 'undefined' || localData === 'null') {
    return null
  }

  return localData
}

export const removeItem = (name) => {
  return localStorage.removeItem(compositeName(name))
}
