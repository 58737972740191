import React, { Component } from 'react'
import Routes from './routes/Routes'

import { ENVIRONMENT, APP_VERSION } from './constants/config'
require(`./assets/scss/${process.env.REACT_APP_WHITE_LABEL}_theme.scss`)

/**
 * Main app component
 */

class App extends Component<> {
  componentDidMount() {
    console.log(`Environment: ${ENVIRONMENT}`)
    console.log(`Version: ${APP_VERSION}`)
  }

  render() {
    return <Routes></Routes>
  }
}

export default App
