import * as storagePersist from 'src/helpers/storagePersist'
import * as CONSTANTS from 'src/constants/config'

const storageName = CONSTANTS.STORAGE_PERSIST_PREFERENCES_DATA

const localStateStorage = storagePersist.getItem(storageName)

const initialStateDefault = {
  pagination: {
    sizePerPage: 10,
  },
}

export const TYPES = {
  SET_SIZE_PER_PAGE: 'setSizePerPage',
  CLEAR_SIZE_PER_PAGE: 'clearSizePerPage',
}

export const initialState = localStateStorage || initialStateDefault

export const reducer = (state, action) => {
  let newState = state
  switch (action.type) {
    case TYPES.SET_SIZE_PER_PAGE:
      newState = {
        ...state,
        pagination: {
          ...state.pagination,
          sizePerPage: action.payload,
        },
        paginationState: null,
      }
      storagePersist.setItem(storageName, newState)
      break

    case TYPES.CLEAR_SIZE_PER_PAGE:
      newState = {
        ...state,
        paginationState: {
          ...initialStateDefault.pagination,
        },
      }
      storagePersist.setItem(storageName, newState)
      break

    default:
      break
  }

  return newState
}
